import { Box } from "styled-system/jsx";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  LegacyTypography,
  TypographyVariant,
  LegacyLoadingSpinner,
} from "@ttc3k/ttc-design-system";
import { Button } from "@ttc3k/trekker";
import { useGetOperatorQuery, useGetFormResponsesQuery, FormResponse } from "gql/generated";
import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal/WhereaboutsModal";
import ModalProfile from "core/components/shared/ModalProfile/ModalProfile";
import { Tag } from "core/components/shared/Tag";
import { EditEntityCard } from "../typeform/TypeFormEditCard/EditEntityCard";
import SlideInPage from "../../animation/SlideInPage.tsx";

interface TSNNTemplateProps {
  baseURL: string;
  next: () => void;
  stepData: {
    title: string;
    description: string;
  };
}

interface TypeformRequestBody {
  form_response: {
    definition: {
      title: string;
      fields: Array<{
        title: string;
      }>;
    };
    answers: Array<{
      text?: string;
      choice?: {
        label: string;
      };
      date?: string;
      number?: number;
      choices?: {
        labels: string[];
      };
    }>;
  };
}

interface ExtendedFormResponse extends FormResponse {
  isActive: boolean;
  startDateTime: string;
  endDateTime: string;
  requestBody: TypeformRequestBody;
  request: FormResponse;
}

export const TSNNTemplate = ({ baseURL, next, stepData }: TSNNTemplateProps) => {
  const { user } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { data: operatorData, loading: operatorLoading } = useGetOperatorQuery({
    variables: { _id: "67d99ed428f2a4c1de8801b5" },
  });

  const { data: formData, loading: formLoading, refetch } = useGetFormResponsesQuery({
    variables: {
      linkedId: user?.sub,
      type: "",
    },
    fetchPolicy: "cache-and-network",
  });

  const handleSave = () => {
    const saveButton = document.querySelector("[data-save-button]") as HTMLButtonElement;
    if (saveButton) {
      saveButton.click();
    }
  };

  if (operatorLoading || formLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[200px]">
        <LegacyLoadingSpinner />
      </div>
    );
  }

  const transformedResponses: ExtendedFormResponse[] = formData?.getFormResponses?.data?.map(response => ({
    ...response,
    isActive: true,
    startDateTime: new Date().toISOString(),
    endDateTime: new Date().toISOString(),
    requestBody: response.requestBody as TypeformRequestBody,
    request: response,
  })) || [];

  return (
    <SlideInPage>
      <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-1">
        <LegacyTypography as="h2" variant={TypographyVariant.h2}>
          {stepData.title}
        </LegacyTypography>
        <LegacyTypography>{stepData.description}</LegacyTypography>

        <div
          className="tw-mt-10 tw-border tw-border-gra-200 tw-rounded-lg tw-p-4 tw-bg-white tw-shadow-md tw-flex tw-flex-col tw-items-center"
        >
          <LegacyTypography variant={TypographyVariant.h4}>
            2025 Application Status
          </LegacyTypography>
          <div className="tw-mt-2 tw-flex tw-items-center tw-gap-2">
            <Tag label="Processing" variant="warning" />
          </div>
        </div>

        {/* <div className="tw-mt-10">
          <div className="tw-mb-4">
            <LegacyTypography variant={TypographyVariant.h3}>
              Your Business Profile
            </LegacyTypography>
          </div>
          <Button 
            onClick={() => setIsModalOpen(true)}
            size="lg"
            visual="primary"
            className="tw-w-full"
          >
            View/Edit Profile
          </Button>
        </div> */}

        <WhereaboutsModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setIsEditing(false);
          }}
          title="Profile"
          size="lg"
          saveButtonProps={{
            onClick: handleSave,
            disabled: !isEditing
          }}
          saveButtonCopy={isEditing ? "Save Changes" : "Edit"}
          cancelButtonCopy="Close"
        >
          <Box id="modal-profile">
            <ModalProfile
              data={operatorData}
              loading={operatorLoading}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Box>
        </WhereaboutsModal>

        {/* <div className="tw-mt-10">
          <div className="tw-mb-4">
            <LegacyTypography variant={TypographyVariant.h3}>
              Form Responses
            </LegacyTypography>
          </div>
          {transformedResponses.map(response => (
            <EditEntityCard
              key={response._id}
              entity={response}
              onUpdateSuccess={() => refetch()}
            />
          ))}
        </div> */}

<div className="tw-flex tw-mt-10 tw-flex-col tw-gap-4 tw-text-left">
  <p>Thanks for registering with Tourism SkillsNet North!</p>

  <p>A representative from NOTO (Nature and Outdoor Tourism Ontario) will be in touch shortly to discuss next steps.</p>

  <p>If after a few minutes you have not received a confirmation email to the address provided, please check your email's Junk Folder. If you have not received a confirmation email, please contact Jan Van Oordt at <a href="mailto:j.vanoordt@destinationnorthernontario.ca" className="tw-underline">j.vanoordt@destinationnorthernontario.ca</a> to follow up.</p>

  <div>
    <p>If you have any immediate questions, please reach out to:</p>
    <ul className="tw-list-none tw-pl-0">
      <li>Anne Awori for Employers in RTO 9 (<a href="mailto:anne@region9tourism.ca" className="tw-underline">anne@region9tourism.ca</a>)</li>
      <li>Kim Clarke for Employers in RTO 7 (<a href="mailto:kclarke@rto7.ca" className="tw-underline">kclarke@rto7.ca</a>)</li>
      <li>Ashley Van Delft for RTO 13 (<a href="mailto:ashley@noto.net" className="tw-underline">ashley@noto.net</a>)</li>
    </ul>
  </div>
</div>

      </div>
    </SlideInPage>
  );
};
