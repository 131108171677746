import { useFormHandler } from "core/hooks/useFormHandler";
import {
  BookerOptions,
  ProductManyQuery,
  useIntegrateProductCalendarLinksByProductIdsMutation,
  useOperatorByIdQuery,
  useOperatorUpdateMutation,
} from "gql/generated";
import { useCallback, useMemo } from "react";
import { z } from "zod";
import { transformCalendarLinksByProduct } from "../utils";

const calendarLinkByProduct = z.object({
  _id: z.string(),
  link: z
    .string()
    .optional()
    .refine(
      link =>
        !link ||
        /^https:\/\/[\w\-.]+(\/[\w\-.~:/?#[\]@!$&'()*+,;=% ]*\.ics(\?[\w\-.~:/?#[\]@!$&'()*+,;=% ]*)?)?$/.test(
          link,
        ),
      {
        message: "Invalid .ics link format",
      },
    ),
});

const settingsFormSchema = z.object({
  termsConditions: z.string(),
  cancellationPolicy: z.string(),
  tax: z.number().optional(),
  calendarLinksByProduct: z.array(calendarLinkByProduct),
});

export type SettingsFormValues = z.infer<typeof settingsFormSchema>;

interface SettingsFormOptions {
  onSuccess?: () => void;
  onError?: (message: string) => void;
  operatorID: string;
  products: ProductManyQuery | undefined;
}

export const useSettingsForm = ({
  onSuccess,
  onError,
  operatorID,
  products,
}: SettingsFormOptions) => {
  const { data: operatorData } = useOperatorByIdQuery({
    variables: { id: operatorID },
  });

  const defaultCalendarlinksByProduct = products?.productMany
    .flatMap(
      p =>
        p.accommodationMetadata?.calendarLinks?.map(link => ({
          _id: p._id,
          link: link ?? "",
        })) ?? { _id: p._id, link: "" },
    )
    .filter(p => p.link.length > 0);

  const defaultValues: SettingsFormValues = useMemo(
    () => ({
      tax:
        operatorData?.operatorById?.bookerOptions?.payment?.taxPercentage ??
        undefined,
      cancellationPolicy:
        operatorData?.operatorById?.bookerOptions?.cancellationPolicy ?? "",
      termsConditions:
        operatorData?.operatorById?.bookerOptions?.termsConditions ?? "",
      calendarLinksByProduct:
        defaultCalendarlinksByProduct &&
        defaultCalendarlinksByProduct.length > 0
          ? defaultCalendarlinksByProduct
          : [
              {
                _id: "",
                link: "",
              },
            ],
    }),
    [
      operatorData?.operatorById?.bookerOptions?.cancellationPolicy,
      operatorData?.operatorById?.bookerOptions?.payment?.taxPercentage,
      operatorData?.operatorById?.bookerOptions?.termsConditions,
      defaultCalendarlinksByProduct,
    ],
  );

  const [updateOperator] = useOperatorUpdateMutation();
  const [integrateExternalProductCalendarLinks] =
    useIntegrateProductCalendarLinksByProductIdsMutation();

  return useFormHandler<SettingsFormValues>(
    useCallback(
      async data => {
        const updatedBookerOptions: BookerOptions = {
          ...(operatorData?.operatorById?.bookerOptions ?? {}),
          cancellationPolicy:
            data?.cancellationPolicy && data.cancellationPolicy.length > 0
              ? data.cancellationPolicy
              : undefined,
          termsConditions:
            data?.termsConditions && data.termsConditions.length > 0
              ? data.termsConditions
              : undefined,
          payment: {
            ...(operatorData?.operatorById?.bookerOptions?.payment ?? {}),
            applicationPercentage:
              operatorData?.operatorById?.bookerOptions?.payment
                ?.applicationPercentage ?? 0.0495,
            taxPercentage:
              typeof data?.tax !== "undefined" && data.tax >= 0
                ? data.tax
                : undefined,
          },
        };

        const updatedProductCalendarLinks = transformCalendarLinksByProduct(
          data?.calendarLinksByProduct,
        );

        const formErrorMessage =
          "Could not save all of your settings, please check your updates and try again";

        try {
          const updateOperatorPromise = updateOperator({
            variables: {
              id: operatorID,
              record: { bookerOptions: updatedBookerOptions },
            },
          });

          let updateProductCalendarLinksPromise;
          if (updatedProductCalendarLinks.length > 0) {
            updateProductCalendarLinksPromise =
              integrateExternalProductCalendarLinks({
                variables: {
                  calendarLinksByProductIDs: updatedProductCalendarLinks,
                },
              });
          }
          await Promise.all([
            updateOperatorPromise,
            updateProductCalendarLinksPromise,
          ]);

          onSuccess?.();
        } catch (error) {
          onError?.(formErrorMessage);
        }
      },
      [
        operatorData?.operatorById?.bookerOptions,
        integrateExternalProductCalendarLinks,
        onError,
        onSuccess,
        operatorID,
        updateOperator,
      ],
    ),
    defaultValues,
    { schema: settingsFormSchema },
  );
};
