import { z } from "zod";
import { initialDaysValues } from "../../../../../../core/components/shared/HoursOfOperation/utils";
import { initialSeasonsValues } from "../../../../../../core/components/shared/Seasonality/utils";
import { isUrl } from "../../../../../../core/utils/isUrl";
import {
  multilingualSchema,
  multilingualSchemaInitialValues,
} from "../../../../../../core/schemas/multilingual";

// Publishing Options
const publishingOptionsSchema = z.string().optional();
export const publishingOptionsOrder = [
  "doNotUpdateListingOnVtcCom",
  "updateListingOnVtcCom",
  "unpublishThisListingOnVtCcom",
] as const;

export const publishingOptionsLabels: Record<
  (typeof publishingOptionsOrder)[number],
  string
> = {
  doNotUpdateListingOnVtcCom: "Do not update listing on VTC.com",
  updateListingOnVtcCom: "Update listing on VTC.com",
  unpublishThisListingOnVtCcom: "Unpublish this listing on VTC.com",
};

// OpenHours Options
export const openHoursOptionsOrder = [
  "not_specified",
  "open_24_7",
  "opening_hours_specified",
] as const;

const openHoursOptionsSchema = z
  .enum(openHoursOptionsOrder)
  .default("not_specified");

export type OpenHoursOptionsSchema = z.infer<typeof openHoursOptionsSchema>;

export const openHoursOptionsLabels: Record<
  (typeof openHoursOptionsOrder)[number],
  string
> = {
  not_specified: "Not specified",
  open_24_7: "My business is open 24/7",
  opening_hours_specified:
    "My business is open on specific days and at specific times",
};

// Up to date source Options
export const upToDateSourceOptionsOrder = [
  "none",
  "google_my_business",
  "instagram",
  "my_website",
  "facebook",
] as const;

const upToDateSourceOptionsSchema = z
  .enum(upToDateSourceOptionsOrder)
  .default("none");

export const upToDateSourceOptionsLabels: Record<
  (typeof upToDateSourceOptionsOrder)[number],
  string
> = {
  none: "-",
  google_my_business: "Google My Business",
  instagram: "Instagram",
  my_website: "My Website",
  facebook: "Facebook",
};

// Checklist
const checklistSchema = z.object({
  verifyAllLinks: z.boolean(),
  tagYourBusiness: z.boolean(),
  verifyLocation: z.boolean(),
  verifyOpeningHours: z.boolean(),
  uploadNewHighResPhotos: z.boolean(),
  isYourBusinessDescriptionAccurateAndCompelling: z.boolean(),
});

type ChecklistSchema = z.infer<typeof checklistSchema>;

const initialChecklistValues: ChecklistSchema = {
  verifyAllLinks: false,
  tagYourBusiness: false,
  verifyLocation: false,
  verifyOpeningHours: false,
  uploadNewHighResPhotos: false,
  isYourBusinessDescriptionAccurateAndCompelling: false,
};

export const checklistOrder: (keyof ChecklistSchema)[] = [
  "verifyAllLinks",
  "tagYourBusiness",
  "verifyLocation",
  "verifyOpeningHours",
  "uploadNewHighResPhotos",
  "isYourBusinessDescriptionAccurateAndCompelling",
];

export const checklistLabels: Record<keyof ChecklistSchema, string> = {
  verifyAllLinks: "Verify all links",
  tagYourBusiness: "Tag your business",
  verifyLocation: "Verify location",
  verifyOpeningHours: "Verify opening hours",
  uploadNewHighResPhotos: "Upload new high-res photos",
  isYourBusinessDescriptionAccurateAndCompelling:
    "Is your business description accurate and compelling?",
};

// Hours of operation
const hoursOfOperationSchema = z.object({
  sun: z.object({ from: z.string(), to: z.string(), isActive: z.boolean() }),
  mon: z.object({ from: z.string(), to: z.string(), isActive: z.boolean() }),
  tue: z.object({ from: z.string(), to: z.string(), isActive: z.boolean() }),
  wed: z.object({ from: z.string(), to: z.string(), isActive: z.boolean() }),
  thu: z.object({ from: z.string(), to: z.string(), isActive: z.boolean() }),
  fri: z.object({ from: z.string(), to: z.string(), isActive: z.boolean() }),
  sat: z.object({ from: z.string(), to: z.string(), isActive: z.boolean() }),
});

// Manual address schema
export const manualAddressSchema = z.object({
  line_1: z.string(),
  line_2: z.string(),
  city: z.string(),
  province: z.string(),
  postal_code: z.coerce.string(),
  latitude: z.coerce.number(),
  longitude: z.coerce.number(),
});

// Tags schema
const tagsSchema = z.object({
  type_of_operator: z.string(),
  capacity: z.coerce.number().default(0),
  region: z.string().array(),
  pricing: z.string(),
  amenities: z.string().array(),
  vtc_custom: z.string().array(),
  accreditations: z.string().array().nullable(),
});

type TagsSchema = z.infer<typeof tagsSchema>;

const defaultTagsValues: TagsSchema = {
  type_of_operator: "",
  capacity: 0,
  region: [],
  pricing: "",
  amenities: [],
  vtc_custom: [],
  accreditations: [],
};

// Seasonality schema
const monthsShema = z.object({
  jan: z.boolean(),
  feb: z.boolean(),
  mar: z.boolean(),
  apr: z.boolean(),
  may: z.boolean(),
  jun: z.boolean(),
  jul: z.boolean(),
  aug: z.boolean(),
  sep: z.boolean(),
  oct: z.boolean(),
  nov: z.boolean(),
  dec: z.boolean(),
});

const seasonalitySchema = z.object({
  high: monthsShema,
  low: monthsShema,
  closed: monthsShema,
});

// Note
const noteSchema = z.object({
  text: z.string(),
  by: z.string(),
  date: z.number().default(Math.floor(Date.now() / 1000)),
  is_public: z.boolean(),
});

type NoteSchema = z.infer<typeof noteSchema>;

const initialNoteValue: NoteSchema = {
  text: "",
  by: "",
  date: Math.floor(Date.now() / 1000),
  is_public: false,
};

export const maxDescriptionCharacters = 600;
export const maxShortDescriptionCharacters = 400;

// Profile schema
export const profileSchema = z
  .object({
    operator_name: z.string().min(1, "Required"),
    website: z.string().trim(),
    phone: z.string(),
    email: z.string().email(),
    line_1: z.string(),
    line_2: z.string(),
    city: z.string(),
    province: z.string(),
    postal_code: z.coerce.string(),
    address: z.string().trim(),
    latitude: z.coerce.number(),
    longitude: z.coerce.number(),
    description: multilingualSchema(maxDescriptionCharacters),
    short_description: multilingualSchema(maxShortDescriptionCharacters),
    tags: tagsSchema,
    facebook_link: z.string().refine(val => val === "" || isUrl(val), {
      message: "Please add complete URL",
    }),
    instagram_link: z.string().refine(val => val === "" || isUrl(val), {
      message: "Please add complete URL",
    }),
    youtube_link: z.string().refine(val => val === "" || isUrl(val), {
      message: "Please add complete URL",
    }),
    pinterest_link: z.string().refine(val => val === "" || isUrl(val), {
      message: "Please add complete URL",
    }),
    google_mybusiness_link: z.string().refine(val => val === "" || isUrl(val), {
      message: "Please add complete URL",
    }),
    tiktok_link: z.string().refine(val => val === "" || isUrl(val), {
      message: "Please add complete URL",
    }),
    tripadvisor_link: z.string().refine(val => val === "" || isUrl(val), {
      message: "Please add complete URL",
    }),
    hours_of_operation_week: hoursOfOperationSchema,
    up_to_date_source: z.string(),
    open_hours_option: openHoursOptionsSchema,
    checklist: checklistSchema,
    publishingOptions: publishingOptionsSchema,
    markAsNeedsAttention: z.boolean().optional(),
    seasonality: seasonalitySchema,
    sta_license_status: z.string(),
    status: z.string(),
    note: noteSchema,
    connections: z.string().array(),
    more_info_link: z.string().refine(val => val === "" || isUrl(val), {
      message: "Please add complete URL",
    }),
  })
  .superRefine(({ address, ...data }, ctx) => {
    if (!address) {
      const parse = manualAddressSchema.safeParse(data);
      if (!parse.success) {
        ctx.addIssue({
          code: "custom",
          message: "Error parsing the manual address",
        });
      }
    }
  });

export type ProfileSchema = z.infer<typeof profileSchema>;

export const profileDefaultValues: ProfileSchema = {
  operator_name: "",
  website: "",
  phone: "",
  email: "",
  line_1: "",
  line_2: "",
  city: "",
  province: "",
  postal_code: "",
  address: "",
  latitude: 0,
  longitude: 0,
  description: multilingualSchemaInitialValues,
  short_description: multilingualSchemaInitialValues,
  tags: defaultTagsValues,
  facebook_link: "",
  instagram_link: "",
  youtube_link: "",
  pinterest_link: "",
  google_mybusiness_link: "",
  tiktok_link: "",
  tripadvisor_link: "",
  hours_of_operation_week: initialDaysValues,
  up_to_date_source: "",
  open_hours_option: "not_specified",
  checklist: initialChecklistValues,
  publishingOptions: "",
  markAsNeedsAttention: false,
  seasonality: initialSeasonsValues,
  sta_license_status: "",
  status: "Updated",
  note: initialNoteValue,
  connections: [],
  more_info_link: "",
};

export const typeOfOperatorLabels = new Map([
  ["accommodation", "Number of guest rooms"],
  ["tours", "Maximum Group Size"],
  ["restaurant", "Seating Capacity"],
]);
