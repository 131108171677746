// @ts-nocheck
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  LegacyButton,
  Sizes,
  LegacyTypography,
  LegacyLoadingSpinner,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { TypeForm } from "../typeform/TypeForm";
import { EditEntityCard } from "../typeform/TypeFormEditCard/EditEntityCard";
import { parseToUTCDate } from "../../../utils/parseToUTCDate";
import { useGetFormResponsesQuery } from "../../../../gql/generated";

interface FormFields {
  type: "Operator" | string;
  [key: string]: string | number | boolean | null | undefined;
}

interface StepData {
  title: string;
  preDescription: string;
  postDescription: string;
  buttonLabel: string;
  formId: string;
  formFields: FormFields;
}

interface AdditionalInfoTemplateProps {
  baseURL: string;
  next?: string;
  stepData: StepData;
}

export const AdditionalInfoTemplate = ({
  baseURL,
  next,
  stepData,
}: AdditionalInfoTemplateProps) => {
  const { user, isLoading: authLoading } = useAuth0();
  const { state } = useLocation();
  const [fetchingForm, setFetchingForm] = useState(false);

  const linked_id =
    stepData.formFields.type === "Operator"
      ? user?.whereaboutsOnboarding?.operatorId || state?.operatorId
      : user?.sub;

  const { data, loading, error, refetch } = useGetFormResponsesQuery({
    variables: {
      linkedId: linked_id,
      type: stepData?.formFields.type,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  const isLoading = loading || authLoading;
  const disableNext = !data?.getFormResponses?.data?.length;

  if (isLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[200px]">
        <LegacyLoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="tw-text-center tw-text-red-500">
        <LegacyTypography variant={TypographyVariant.error}>
          An error occurred while loading your information. Please try again later.
        </LegacyTypography>
      </div>
    );
  }

  const handleFormClose = async () => {
    setFetchingForm(true);
    await new Promise(resolve => setTimeout(resolve, 1500));
    setFetchingForm(false);
    refetch();
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-5">
      <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-5">
        <LegacyTypography as="h2" variant="H2">
          {stepData.title}
        </LegacyTypography>
        <LegacyTypography>{stepData.preDescription}</LegacyTypography>
      </div>

      {disableNext && (
        <div className="tw-w-full md:tw-w-96 tw-mx-auto">
          <div className="tw-w-full tw-flex tw-justify-center">
            <TypeForm
              id={stepData.formId}
              onClose={handleFormClose}
              label={stepData.buttonLabel}
              autoClose={500}
              className="tw-w-full"
              buttonClassName="tw-w-full tw-py-3 tw-px-4 tw-text-center tw-bg-primary-600 tw-text-white tw-rounded-lg hover:tw-bg-primary-700 tw-transition-colors"
              hidden={{
                ...stepData.formFields,
                name: `${user?.given_name} ${user?.family_name}`,
                linked_id: linked_id,
              }}
            />
          </div>
          {fetchingForm && (
            <div className="tw-flex tw-justify-center tw-mt-4">
              <LegacyLoadingSpinner />
            </div>
          )}
        </div>
      )}

      <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-1">
        {data?.getFormResponses?.data?.map(request => (
          <EditEntityCard
            entity={{
              isActive: true,
              startDateTime: parseToUTCDate(new Date()),
              endDateTime: "2024-03-29T13:48:53Z",
              title: request.requestBody.form_response.definition.title,
              request: request,
            }}
            onUpdateSuccess={() => refetch()}
            key={request._id}
          />
        ))}
      </div>

      <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-5">
        {!disableNext && (
          <LegacyTypography
            variant={TypographyVariant.secondaryMedium}
            color={"#27704C"}
          >
            If you wish to change the private info above, you must delete and
            re-submit the form.
          </LegacyTypography>
        )}
        
        <LegacyTypography>{stepData.postDescription}</LegacyTypography>

        {next && (
          <Link className="tw-pt-6" to={`${baseURL}/${next}`} state={state}>
            <LegacyButton disabled={disableNext} fullWidth size={Sizes.Large}>
              Next
            </LegacyButton>
          </Link>
        )}
      </div>
    </div>
  );
};
