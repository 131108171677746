// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Fragment } from "react";
import { gql, useQuery } from "@apollo/client";
import { nanoid } from "nanoid";
import { ErrorBoundary } from "@sentry/react";
import { PublicReservationPage } from "core/components/shared/ReservationPage";
import { Loader } from "./core/components/Loader";
import OnboardingLayout from "./core/pages/onboarding/Layout";
import { ProtectedRoute } from "./ProtectedRoute";
import { Layout } from "./core/Layout";
import TermsAndConditions from "./core/pages/public/TermsAndConditions";
import TripperPlayground from "./core/pages/public/tripper-launcher-playground/TripperPlayground.jsx";
import { Pending } from "./core/pages/onboarding/templates/PendingTemplate.tsx";
import { CreateBusiness } from "./core/pages/onboarding/templates/claimSteps/2a-CreateNewBusiness.tsx";
import { SearchForBusiness } from "./core/pages/onboarding/templates/claimSteps/2b-SearchForBusiness.tsx";
import { ClaimFoundBusiness } from "./core/pages/onboarding/templates/claimSteps/2-ClaimBusiness.tsx";
import { Verify } from "./core/pages/onboarding/templates/claimSteps/2c-Verify.tsx";
import { ClaimWithCode } from "./core/pages/onboarding/templates/claimSteps/2d-EnterCode.tsx";
import { AlreadyOnboarded } from "./core/pages/auth/AlreadyOnboarded.tsx";
import SignUp from "./core/pages/auth/SignUp.tsx";
import SignIn from "./core/pages/auth/SignIn.tsx";
import { VerifyEmail } from "./core/pages/auth/VerifyEmail.tsx";
import TemplateLoader from "./core/pages/onboarding/TemplateLoader.tsx";
import Onboarding404 from "./core/pages/onboarding/Onboarding404.tsx";
import { AddAddressToNewBusiness } from "./core/pages/onboarding/templates/claimSteps/2ab-AddAddressToNewBusiness.tsx";
import { SelectOrg } from "./core/pages/auth/SelectOrg.tsx";
import { Doorstop } from "./core/components/shared/Doorstop";
import { useSiteSettings } from "./SiteSettingsContext.tsx";
import { AcceptInvitation } from "./core/pages/onboarding/templates/claimSteps/1-AcceptInvitation.tsx";
import ErrorPage from "./core/pages/ErrorPage.tsx";
import { Booker } from "./apps/custom/ttc/booker/App.tsx";

const GET_ORG = gql`
  query Org($slug: String) {
    org(slug: $slug) {
      slug
      name
      customPortals
      logo {
        full_url
      }
      tripperSettings {
        teamId
      }
    }
  }
`;

export default function AuthFlow({ children }: { children: JSX.Element }) {
  const siteSettings = useSiteSettings();
  const maintenanceMode = siteSettings.maintenanceMode;

  //https://auth0.com/docs/quickstart/spa/react/02-calling-an-api
  const { user, isLoading } = useAuth0();
  const location = useLocation();
  const { pathname } = location;
  const slug = pathname.split("/")[2];
  const program = pathname.split("/")[3];
  const {
    data: customPortalData,
    loading: customPortalLoading,
    error: customPortalError,
  } = useQuery(GET_ORG, {
    variables: { slug: slug },
    skip: !window.location.pathname.includes("onboard"),
  });

  //First check to see if the site is in maintenance mode
  if (maintenanceMode && maintenanceMode.doorstop.isLive) {
    return (
      <>
        <Routes>
          <Route element={<OnboardingLayout />}>
            <Route path="/admin-maintenance-mode-login" element={<SignIn />} />
          </Route>
        </Routes>
        <div
          className={"tw-h-screen tw-flex tw-items-center tw-justify-center"}
        >
          <Doorstop message={maintenanceMode.doorstop.message} />
        </div>
      </>
    );
  }

  if (isLoading || customPortalLoading) {
    return (
      <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
        <Loader type="LOGO" />
      </div>
    );
  }

  const orgData = customPortalData?.org;
  const customPortal = orgData?.customPortals?.[program];
  const isCustomOnboardingFlow = customPortal != undefined;

  // This onboarding flow supports users in four states:
  // A. Not authenticated
  // B. Authenticated and not onboarded
  // C. Authenticated and finished onboarding
  // D. Authenticated and onboarded BUT just logged in through the wrong portal
  // This is how we do it:
  // 1. Check if user is logged in.
  // 2. If so, check if onboarding is complete and no onboarding is NotPending. If True, exit out of AuthFlow and enter WA.
  // 3. If onboarding is not complete or pending, go to the page in user_metadata or first page of onboarding
  // 4. If not logged in: Check URL. If it includes 'onboarding' or 'onboard', query getOrgs
  // 5. If getOrgs returns true, display the CustomOnboarding page for sign-in or sign-up or redirect to sign-in
  // 6. If not logged in and no getOrgs, go to /sign-up
  // 7. After regular /sign-up, go to default AuthFlow which ends with a help message to WA.

  //Check if URL is part of Custom Onboarding Flow

  //Check if user is logged in and has completed the AuthFlow, if so continue to app.tsx
  if (user && user?.whereaboutsOnboarding?.complete) {
    return children;
  }

  //Available Routes if NOT authenticated
  //@ToDo: could we do more here to differentiate 404 pages from Need to Login?
  if (!user) {
    const mockBookerId = "665f458e663afcfcf1644796";
    // const mockBookerId = "6671daf59866daec941e5275";

    return (
      <Routes>
        <Route
          path="/booker-test"
          element={
            <div
              style={{
                minHeight: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Booker bookerID={mockBookerId} />
            </div>
          }
        />
        <Route
          path="/booker/reservation/:id"
          element={<PublicReservationPage />}
        />
        {/*Default sign-in and sign-up*/}
        <Route element={<OnboardingLayout />}>
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/select" element={<SelectOrg />} />
          <Route path="/sign-in" element={<SignIn />} />
        </Route>
        {/*Custom sign-in and sign-up pages*/}
        {isCustomOnboardingFlow && (
          <Route
            path="/onboarding"
            element={
              <OnboardingLayout
                isCustomOnboardingFlow={isCustomOnboardingFlow}
                customPortalData={customPortal}
                customPortalLoading={customPortalLoading}
                customPortalError={customPortalError}
                user={user}
                baseURL={`/onboarding/${slug}/${program}`}
              />
            }
          >
            {/*Sign-up can be disabled. In which case, load a CoverPage*/}
            {customPortal?.settings?.allowSignIns == false ? (
              <Route
                path={`:slug/:program/`}
                element={
                  <TemplateLoader
                    baseURL={`/onboarding/${slug}/${program}`}
                    next={customPortal.coverPage.next}
                    type={customPortal.coverPage.template}
                    stepData={customPortal.coverPage}
                  />
                }
              />
            ) : (
              <>
                <Route path=":slug/:program" element={<SignIn />} />
                <Route path=":slug/:program/sign-up" element={<SignUp />} />
                <Route
                  path=":slug/:program/join"
                  element={<AlreadyOnboarded />}
                />
              </>
            )}
            {/*Clients have option of adding a page in between Sign In and Sign Up*/}
            {customPortal?.preBoarding != undefined && (
              <Route
                path={`:slug/:program/${customPortal.preBoarding.slug}`}
                element={
                  <TemplateLoader
                    baseURL={`/onboarding/${slug}/${program}`}
                    next={customPortal.preBoarding.next}
                    type={customPortal.preBoarding.template}
                    stepData={customPortal.preBoarding}
                  />
                }
              />
            )}
            <Route
              path={"*"}
              element={<Navigate to={`/onboarding/${slug}/${program}`} />}
            />
          </Route>
        )}
        <Route path="/open" element={<Layout />}>
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route
            path="tripper-playground/:slug"
            element={<TripperPlayground />}
          />
        </Route>
        {/*<Route path={"*"} element={<PageNotFound />} />*/}
        <Route path={"*"} element={<Navigate to={"/sign-in"} />} />
      </Routes>
    );
  }

  //All routes below are for authenticated users

  //Check to see if email is verified
  if (user && !user?.email_verified) {
    return (
      <Routes>
        <Route path="/onboarding" element={<OnboardingLayout user={user} />}>
          <Route path="verify-email" element={<VerifyEmail user={user} />} />
        </Route>
        <Route path="*" element={<Navigate to="/onboarding/verify-email" />} />
      </Routes>
    );
  }

  //Check to see if onboarding is pending
  if (user && user?.whereaboutsOnboarding?.pending) {
    return (
      <Routes>
        <Route path="/onboarding" element={<OnboardingLayout user={user} />}>
          <Route
            path="in-review"
            element={
              <ProtectedRoute user={user}>
                <Pending />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/onboarding/in-review" />} />
      </Routes>
    );
  }

  //Onboarding Flow
  try {
    if (
      user?.whereaboutsOnboarding?.complete == false ||
      user?.whereaboutsOnboarding?.complete === undefined
    ) {
      if (isCustomOnboardingFlow) {
        return (
          <Routes>
            <Route
              path="/"
              element={
                user?.whereaboutsOnboarding?.complete ? (
                  <Navigate to={"/"} />
                ) : (
                  <Onboarding404 />
                )
              }
            />
            <Route
              path={`/onboarding/${slug}/${program}/sign-up`}
              element={<Navigate to={`/onboarding/${slug}/${program}/${customPortal?.onboarding?.[0]?.slug}`} />}
            />
            <Route
              path="/onboarding"
              element={
                <OnboardingLayout
                  isCustomOnboardingFlow={isCustomOnboardingFlow}
                  customPortalData={customPortal}
                  customPortalLoading={customPortalLoading}
                  customPortalError={customPortalError}
                  user={user}
                  baseURL={`/onboarding/${slug}/${program}`}
                />
              }
            >
              <Route
                path={`${slug}/${program}/in-review`}
                element={<Pending />}
              />
              {customPortal?.onboarding?.map((step, index) => {
                if (step.template == "CLAIM") {
                  return (
                    <Fragment key={nanoid()}>
                      <Route
                        key={step.slug}
                        path={`${slug}/${program}/${step.slug}`}
                        element={
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <TemplateLoader
                              baseURL={`/onboarding/${slug}/${program}`}
                              next={customPortal?.onboarding[index + 1]?.slug}
                              type={step.template}
                              stepData={step}
                            />
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        key={`${step.slug}-accept-invite`}
                        path={`${slug}/${program}/${step.slug}/accept-invitation`}
                        element={
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <AcceptInvitation
                              urlAfterClaimFlow={
                                customPortal?.onboarding[index + 1]?.slug
                              }
                            />
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        key={`${step.slug}-found`}
                        path={`${slug}/${program}/${step.slug}/found`}
                        element={
                          <ClaimFoundBusiness
                            urlAfterClaimFlow={
                              customPortal?.onboarding[index + 1]?.slug
                            }
                          />
                        }
                      />
                      <Route
                        key={`${step.slug}-search`}
                        path={`${slug}/${program}/${step.slug}/search`}
                        element={<SearchForBusiness />}
                      />
                      <Route
                        key={`${step.slug}-verify`}
                        path={`${slug}/${program}/${step.slug}/verify`}
                        element={<Verify />}
                      />
                      <Route
                        key={`${step.slug}-enter-code`}
                        path={`${slug}/${program}/${step.slug}/enter-code`}
                        element={
                          <ClaimWithCode
                            urlAfterClaimFlow={
                              customPortal?.onboarding[index + 1]?.slug
                            }
                          />
                        }
                      />
                      <Route
                        key={`${step.slug}-create`}
                        path={`${slug}/${program}/${step.slug}/create`}
                        element={<CreateBusiness />}
                      />
                      <Route
                        key={`${step.slug}-create-address`}
                        path={`${slug}/${program}/${step.slug}/create-address`}
                        element={
                          <AddAddressToNewBusiness
                            urlAfterClaimFlow={
                              customPortal?.onboarding[index + 1]?.slug
                            }
                          />
                        }
                      />
                    </Fragment>
                  );
                }
                return (
                  <Route
                    key={step.slug}
                    path={`${slug}/${program}/${step.slug}`}
                    element={
                      <TemplateLoader
                        baseURL={`/onboarding/${slug}/${program}`}
                        next={customPortal?.onboarding[index + 1]?.slug}
                        type={step.template}
                        stepData={step}
                      />
                    }
                  />
                );
              })}
            </Route>
            <Route
              path="*"
              element={
                user?.whereaboutsOnboarding?.signUp?.pages != "" &&
                user?.whereaboutsOnboarding?.signUp?.pages != undefined ? (
                  <Navigate to={user?.whereaboutsOnboarding?.signUp?.pages} />
                ) : (
                  <Navigate to={`/onboarding/${slug}/${program}/claim`} />
                )
              }
            />
          </Routes>
        );
      } else {
        return (
          <Routes>
            <Route element={<OnboardingLayout />}>
              <Route path="/select" element={<SelectOrg />} />
            </Route>
            <Route
              path="*"
              element={
                user?.whereaboutsOnboarding?.signUp?.pages != "" &&
                user?.whereaboutsOnboarding?.signUp?.pages != undefined ? (
                  <Navigate to={user?.whereaboutsOnboarding?.signUp?.pages} />
                ) : (
                  <Navigate to={`/select`} />
                )
              }
            />
          </Routes>
        );
      }
    }
  } catch (e) {
    console.log(e);
    return <ErrorPage />;
  }

  //Tenants are currently stored in Auth0 in the add_metadata field. To add more fields, the Action flow must be edited.
  //Users must be a member of a tenant. If they aren't, we'll add a flow so they can associate themselves.
  // if (user?.whereaboutsTenants) {
  //   // return (
  //   //     <div className="h-screen flex flex-col items-center justify-center">
  //   //         <h2>You are a member of {user.whereaboutsTenants[0].name}</h2>
  //   //     </div>
  //   // )
  // } else {
  //   const isTTCorTNB =
  //     /(@tourism.tech|@thenew.business|@thenewbusiness.ca)\s*$/.test(
  //       user.email as string,
  //     )
  //       ? true
  //       : false;
  //   // return (
  //   //   <div className="tw-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
  //   //     <h2>To use Whereabouts, you must be a member of an organization.</h2>
  //   //     {isTTCorTNB && (
  //   //       <p>
  //   //         You are clearly a TNB or TTC employee so we should automatically
  //   //         assign your tenant but we haven't built that endpoint yet.
  //   //       </p>
  //   //     )}
  //   //   </div>
  //   // );
  // }

  return children;
}
