import {
  LegacyButton,
  ButtonTagVariant,
  Sizes,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  Calendar,
  HelpCircle,
  LaptopDevMode,
  Map,
  ViewGrid,
  Xmark,
  Apple,
  DollarCircleSolid,
  WebWindowEnergyConsumption,
  PeopleTag,
  Page,
} from "iconoir-react";
import { useMediaQuery } from "react-responsive";
import { AnimatePresence, motion } from "framer-motion";
import { HomeAlt } from "iconoir-react";
import { MaintenanceData } from "../../apps/library/directory/api/typeDefs";
import { UserMenu } from "./UserMenu";
import { EnvLabel } from "./shared/EnvLabel";
import { Banner } from "./shared/Banner";
import { Divider } from "./shared/Divider";
import { Dialog } from "./shared/Dialog";

interface MenuProps {
  registeredApps: any;
  isScrolling: boolean;
  banner: MaintenanceData | undefined;
  organization: any;
}

const getRegisteredAppIcon = (app: any) => {
  switch (app.name) {
    case "Directory":
      return (
        <HomeAlt strokeWidth={1.75} color="#A1A1AA" width={20} height={20} />
      );
    case "Help":
      return (
        <HelpCircle strokeWidth={1.75} color="#A1A1AA" width={20} height={20} />
      );
    case "Booker":
      return (
        <DollarCircleSolid
          strokeWidth={1.75}
          color="#A1A1AA"
          width={20}
          height={20}
        />
      );
    case "Membership":
      return (
        <PeopleTag strokeWidth={1.75} color="#A1A1AA" width={20} height={20} />
      );
    case "Dev":
      return (
        <LaptopDevMode
          strokeWidth={1.75}
          color="#A1A1AA"
          width={20}
          height={20}
        />
      );
    case "Tripper":
      return <Map strokeWidth={1.75} color="#A1A1AA" width={20} height={20} />;
    case "Events":
      return (
        <Calendar strokeWidth={1.75} color="#A1A1AA" width={20} height={20} />
      );
    case "Learner":
      return (
        <Apple strokeWidth={1.75} color="#A1A1AA" width={20} height={20} />
      );
    case "TEN":
      return (
        <Apple strokeWidth={1.75} color="#A1A1AA" width={20} height={20} />
      );
    case "TSNN":
      return (
        <Page strokeWidth={1.75} color="#A1A1AA" width={20} height={20} />
      );
    case "Site Manager":
      return (
        <WebWindowEnergyConsumption
          strokeWidth={1.75}
          color="#A1A1AA"
          width={20}
          height={20}
        />
      );
    default:
      return null;
  }
};

export const Menu = ({
  registeredApps,
  isScrolling,
  banner,
  organization,
}: MenuProps) => {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEventBannerOpen, setIsEventBannerOpen] = useState(false);
  const handleMenuOpen = () => setIsMenuOpen(!isMenuOpen);

  const menuVariants = {
    closed: {
      opacity: 0,
      width: 0,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 0.4,
      },
    },
    open: {
      opacity: 1,
      width: isMobile ? "100%" : 300,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 0.4,
      },
    },
  };

  const handleOnCloseBanner = () => {
    localStorage.setItem("eventBanner", "false");
    setIsEventBannerOpen(false);
  };

  useEffect(() => {
    //const eventBanner = localStorage.getItem("eventBanner");
    //const eventBanner = "false";
    //if (eventBanner !== "false") setIsEventBannerOpen(true);
    setIsEventBannerOpen(false);
  }, []);

  const filteredApps = registeredApps
    .map(app => {
      if (app.machineName === "learner") {
        return { ...app, slug: "learner/courses" };
      }
      return app;
    })
    .filter(
      app =>
        app.machineName !== "membership" &&
        app.machineName !== "sitemanager" &&
        app.machineName !== "ten" &&
        app.machineName !== "tsnn",
    );

  const DMOOnlyApps = registeredApps.filter(
    app =>
      app.machineName === "membership" ||
      app.machineName === "sitemanager" ||
      app.machineName === "ten"||
      app.machineName === "tsnn",
  );

  return (
    <>
      <div className="tw-sticky tw-top-0 tw-bg-trekYellow tw-z-10">
        {banner && banner.isLive && (
          <Banner
            typographyClassName="tw-col-start-2"
            message={banner.message}
          />
        )}

        <Dialog
          isOpen={isEventBannerOpen}
          onClose={handleOnCloseBanner}
          className="tw-flex tw-flex-col tw-gap-6 tw-shadow-none tw-rounded-xl"
        >
          <div className="wistia_responsive_padding">
            <div className="wistia_responsive_wrapper">
              <div className="wistia_embed wistia_async_1abzep23eb seo=false videoFoam=true">
                <div className="wistia_swatch">
                  <img
                    src="https://fast.wistia.com/embed/medias/1abzep23eb/swatch"
                    alt=""
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-4">
            <span className="tw-text-[#27272A] tw-text-[18px] tw-font-semibold tw-font-figtree">
              🎉 Introducing the Events App
            </span>
            <span className="tw-text-[#27272A] tw-text-[16px] tw-font-normal tw-font-figtree tw-text-justify tw-hyphens-auto">
              You now have access to a new app to help you create and manage
              events for your business. Check it out and let us know if you have
              any questions along the way!
            </span>
          </div>
          <div className="tw-w-full tw-flex tw-justify-between tw-gap-3">
            <LegacyButton
              variant={ButtonTagVariant.Secondary}
              onClick={handleOnCloseBanner}
              type="button"
              fullWidth
            >
              Maybe later
            </LegacyButton>
            <Link
              to="/app/events"
              onClick={handleOnCloseBanner}
              className="tw-w-full"
            >
              <LegacyButton type="button" fullWidth>
                Take me there!
              </LegacyButton>
            </Link>
          </div>
        </Dialog>

        <div
          className={`tw-relative tw-pt-6 tw-pb-4 tw-px-8 tw-flex tw-items-center ${
            isScrolling && "tw-border-b-[1px] tw-border-border"
          }`}
        >
          <LegacyButton
            className={"tw-flex-1"}
            variant={ButtonTagVariant.Unstyled}
            size={Sizes.Small}
            color={"#86765F"}
            iconStart
            icon={<ViewGrid width={24} height={24} />}
            onClick={handleMenuOpen}
          >
            {!isMobile && "Apps"}
          </LegacyButton>
          <div className={"tw-flex tw-items-center tw-gap-2"}>
            <NavLink
              className={
                "tw-font-figtree tw-font-bold tw-tracking-[0.75px] tw-text-[15px] tw-text-[#27272A] tw-uppercase tw-leading-[15px]"
              }
              to={"/"}
            >
              Whereabouts
            </NavLink>
            <EnvLabel />
          </div>
          <div className={"tw-flex-1 tw-text-right"}>
            <UserMenu />
          </div>
        </div>
      </div>
      <AnimatePresence mode={"wait"}>
        {isMenuOpen && (
          <motion.div
            key={"wbts-menu"}
            className={
              "tw-fixed tw-left-0 tw-top-0 tw-z-20 tw-h-screen tw-bg-[#3B4B59] tw-flex tw-flex-col"
            }
            variants={menuVariants}
            initial={"closed"}
            animate={"open"}
            exit={"closed"}
          >
            <div
              className={
                "tw-pt-6 tw-pb-4 tw-px-8 tw-min-h-[78px] tw-flex tw-items-center tw-justify-between tw-text-white tw-opacity-50"
              }
            >
              <LegacyButton
                variant={ButtonTagVariant.Unstyled}
                size={Sizes.Small}
                color={"white"}
                iconStart
                icon={<ViewGrid width={24} height={24} />}
              >
                Apps
              </LegacyButton>
              <LegacyButton
                variant={ButtonTagVariant.Unstyled}
                icon={<Xmark strokeWidth={2} />}
                onClick={handleMenuOpen}
              />
            </div>
            <Divider className="tw-bg-[#4F5F6C]" />
            <div className={"tw-flex tw-p-8 tw-flex-col tw-h-full"}>
              {filteredApps && (
                <div className={"tw-flex tw-flex-col tw-gap-space-200"}>
                  {filteredApps.map(app => {
                    return (
                      <NavLink
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        state={{ previousLocation: location }}
                        to={`app/${app.slug}`}
                        className="tw-flex tw-gap-2 tw-text-white tw-items-center"
                        key={app.slug}
                      >
                        {getRegisteredAppIcon(app)}
                        <LegacyTypography
                          variant={TypographyVariant.secondaryMedium}
                        >
                          {app.name}
                        </LegacyTypography>
                      </NavLink>
                    );
                  })}
                </div>
              )}
              {DMOOnlyApps.length > 0 && (
                <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-space-200">
                  <LegacyTypography
                    variant={TypographyVariant.tertiaryMedium}
                    color={"#7f8e9f"}
                    className={
                      "tw-border-b-[1px] tw-text-[#4F5F6C] tw-border-b-[#4F5F6C]"
                    }
                  >
                    DMO ONLY
                  </LegacyTypography>
                  {DMOOnlyApps.map(app => {
                    return (
                      <NavLink
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        state={{ previousLocation: location }}
                        to={`app/${app.slug}`}
                        className="tw-flex tw-gap-2 tw-text-white tw-items-center"
                        key={app.slug}
                      >
                        {getRegisteredAppIcon(app)}
                        <LegacyTypography
                          variant={TypographyVariant.secondaryMedium}
                        >
                          {app.name}
                        </LegacyTypography>
                      </NavLink>
                    );
                  })}
                </div>
              )}
              <div className={"tw-mt-auto"}>
                <LegacyTypography
                  variant={TypographyVariant.tertiaryMedium}
                  color={"white"}
                >
                  Whereabouts v1.9
                </LegacyTypography>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
