import { gql, useQuery } from "@apollo/client";
import { Loader } from "../../components/Loader.tsx";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { TextField } from "../../components/shared/TextField";
import { userSchema, userDefaultValues } from "./emailSchema.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  LegacyButton,
  LegacyLoadingSpinner,
  LegacyTypography,
} from "@ttc3k/ttc-design-system";
import {
  useCreateUserMutation,
  useGetUserStatusByEmailLazyQuery,
} from "../../../gql/generated.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { useOnboardingContext } from "../onboarding/Layout.tsx";
import { getCompleteURL } from "../../utils/getCompleteURL.ts";
import { LockIcon } from "../../../apps/library/directory/branches/leaves/Profile.tsx";

export default function SignIn() {
  const { user } = useAuth0();
  const navigate = useNavigate();

  //Variables for determining if this is default or custom signup
  const { isCustomOnboardingFlow, program, customPortalData, baseURL } =
    useOnboardingContext();

  //if user is logged in, then redirect
  useEffect(() => {
    if (user) {
      if (user?.whereaboutsOnboarding.signUp.pages) {
        navigate(user?.whereaboutsOnboarding.signUp.pages[0]);
      } else {
        navigate("/");
      }
    }
  }, []);

  //React Hook Form
  const form = useForm<userSchema>({
    resolver: zodResolver(userSchema),
    defaultValues: userDefaultValues,
    mode: "onChange",
  });
  const [email, setEmail] = useState(null);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    setValue,
  } = form;

  const [createUser, { data: saved, loading: loadingUser }] =
    useCreateUserMutation();

  let passedEmail;

  useEffect(() => {
    if (passedEmail) setValue("email", passedEmail, { shouldValidate: true });
  }, []);

  //Check if this is an invite to join
  const [queryParameters] = useSearchParams();
  const inviteDataEncoded = queryParameters.get("invite") ?? false;
  let inviteDataObject;
  try {
    const inviteData = inviteDataEncoded ? atob(inviteDataEncoded) : false;
    inviteDataObject = JSON.parse(inviteData);
  } catch (error) {
    return (
      <LegacyTypography variant="H3">
        Something is wrong with the invite you were sent.
        <br />
        Please contact the member who invited you.
      </LegacyTypography>
    );
  }
  passedEmail = inviteDataObject.inviteEmail;

  //Redirect after email entry
  const { loginWithRedirect } = useAuth0();
  const [getUserStatus, { loading, data }] = useGetUserStatusByEmailLazyQuery();
  const onSubmit: SubmitHandler<userSchema> = async formData => {
    setEmail(formData.email);
    await getUserStatus({
      variables: {
        email: formData.email,
      },
    });
  };

  useEffect(() => {
    // if account exists, redirect to login page
    if (data?.getUserStatus?.email) {
      loginWithRedirect({
        authorizationParams: {
          login_hint: data.getUserStatus.email,
        },
        appState: {
          program: program,
          baseURL: baseURL,
          returnTo: `${baseURL}/claim`,
        },
      });
    }

    //if account doesn't exist, pass on email to signup page
    if (data?.getUserStatus?.account == false) {
      const link = isCustomOnboardingFlow
        ? `${getCompleteURL()}/${
            customPortalData?.signIn.nextIfNewAccount
              ? customPortalData?.signIn.nextIfNewAccount
              : "sign-up"
          }`
        : "/select";
      navigate(link, {
        state: { email: email },
      });
    }
  }, [data, email, program]);

  return (
    <div className="tw-mx-auto tw-full md:tw-w-96 tw-flex tw-flex-col tw-gap-5">
      {isCustomOnboardingFlow ? (
        <>
          <LegacyTypography variant="H2">
            {customPortalData?.signIn.title}
          </LegacyTypography>
          <LegacyTypography>
            {customPortalData?.signIn.description}
          </LegacyTypography>
        </>
      ) : (
        <LegacyTypography>
          To get started, please enter your email
        </LegacyTypography>
      )}
      <div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"tw-flex tw-flex-col tw-items-center tw-gap-4"}>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) =>
                  passedEmail ? (
                    <TextField
                      value={passedEmail}
                      label="Email"
                      autoComplete="username"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      endIcon={<LockIcon />}
                      disabled
                    />
                  ) : (
                    <TextField
                      {...field}
                      label="Email"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      autoComplete="username"
                      //endIcon={<LockIcon />}
                      //disabled
                    />
                  )
                }
              />
              <LegacyButton fullWidth onClick={() => handleSubmit(onSubmit)()}>
                {loading || data ? (
                  <LegacyLoadingSpinner size={20} />
                ) : (
                  "Continue"
                )}
              </LegacyButton>
            </div>
          </form>
          {!isCustomOnboardingFlow && (
            <div className="tw-w-full tw-mt-5 tw-justify-center tw-items-center tw-inline-flex tw-flex-col">
              <div className="tw-text-zinc-400 tw-text-xs tw-center tw-font-normal tw-font-['Menlo'] tw-leading-normal">
                <a href="https://tourism.tech">
                  The Tourism Technology Company
                </a>{" "}
                ⓒ 2025
              </div>
              {/*<div className="tw-text-zinc-400 tw-text-xs tw-font-normal tw-font-['Menlo'] tw-underline tw-leading-normal">*/}
              {/*  Terms & Conditions*/}
              {/*</div>*/}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
