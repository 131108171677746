import { nanoid } from "nanoid";
import styles from "./TypeFormSubmission.module.css";

export const TypeFormSubmission = ({ submission }) => {
  return (
    <div className={styles["typeform-submission"]}>
      {submission.requestBody?.form_response?.definition.fields.map(
        (field, index) => {
          return (
            <div key={nanoid()} className={styles["submission-field"]}>
              <p className={styles["field-title"]}>{field.title}</p>
              <p className={styles["field-value"]}>
                {submission.requestBody.form_response.answers[index].text}
                {
                  submission.requestBody.form_response.answers[index].choice
                    ?.label
                }
                {submission.requestBody.form_response.answers[index].date}
                {submission.requestBody.form_response.answers[index].number}
                {submission.requestBody.form_response.answers[
                  index
                ].choices?.labels.map(r => `${r} | `)}
              </p>
            </div>
          );
        },
      )}
    </div>
  );
};
