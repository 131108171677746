import React, { useState } from "react";
import {
  LegacyTypography,
  TypographyVariant,
  LegacyButton,
  ButtonTagVariant,
  LegacyToast,
  StatusVariants,
} from "@ttc3k/ttc-design-system";
import { Box, Flex } from "styled-system/jsx";
import { TextField } from "core/components/shared/TextField";
import { TextArea } from "core/components/shared/TextArea";
import { Divider } from "core/components/shared/Divider";
import { GetOperatorQuery } from "gql/generated";
import { useUpdateOperator } from "apps/library/directory/branches/leaves/Profile/utils";

interface ModalProfileProps {
  data?: GetOperatorQuery;
  loading?: boolean;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

const ModalProfile: React.FC<ModalProfileProps> = ({ data, loading, isEditing, setIsEditing }) => {
  const operator = data?.getOperator;
  const [formData, setFormData] = useState({
    operator_name: operator?.operator_name || "",
    address: operator?.address || "",
    email: operator?.email || "",
    phone: operator?.phone || "",
    website: operator?.website || "",
  });
  const [error, setError] = useState<string | null>(null);

  const [updateOperator, { loading: updating }] = useUpdateOperator(
    operator?._id ? operator._id : undefined
  );

  React.useEffect(() => {
    if (operator) {
      setFormData({
        operator_name: operator.operator_name || "",
        address: operator.address || "",
        email: operator.email || "",
        phone: operator.phone || "",
        website: operator.website || "",
      });
    }
  }, [operator]);

  const handleChange = (field: keyof typeof formData) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  const handleSave = async () => {
    if (!operator?._id) return;

    try {
      await updateOperator({
        variables: {
          id: operator._id,
          record: {
            operator_name: formData.operator_name,
            address: formData.address,
            email: formData.email,
            phone: formData.phone,
            website: formData.website,
          }
        }
      });
      setIsEditing(false);
      setError(null);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "Failed to update operator";
      setError(errorMessage);
    }
  };

  if (loading) {
    return (
      <Box p={4}>
        <LegacyTypography variant={TypographyVariant.h4}>
          Loading...
        </LegacyTypography>
      </Box>
    );
  }

  if (!operator) {
    return (
      <Box p={4}>
        <LegacyTypography variant={TypographyVariant.h4}>
          No operator data found.
        </LegacyTypography>
      </Box>
    );
  }

  return (
    <Box p={4}>
      {error && (
        <Box mb={4}>
          <LegacyToast variant={StatusVariants.Error}>
            {error}
          </LegacyToast>
        </Box>
      )}
      
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <LegacyTypography variant={TypographyVariant.h3}>
          Operator Details
        </LegacyTypography>
        <LegacyButton
          onClick={() => setIsEditing(!isEditing)}
          variant={ButtonTagVariant.Secondary}
          disabled={updating}
        >
          {isEditing ? "Cancel" : "Edit"}
        </LegacyButton>
      </Flex>

      <Divider />

      <Box mt={4}>
        <TextField
          label="Operator Name"
          value={formData.operator_name}
          disabled={!isEditing || updating}
          onChange={handleChange("operator_name")}
        />

        <Box mt={3}>
          <TextArea
            label="Address"
            value={formData.address}
            disabled={!isEditing || updating}
            onChange={handleChange("address")}
          />
        </Box>

        <Box mt={3}>
          <TextField
            label="Email"
            value={formData.email}
            disabled={!isEditing || updating}
            onChange={handleChange("email")}
          />
        </Box>

        <Box mt={3}>
          <TextField
            label="Phone"
            value={formData.phone}
            disabled={!isEditing || updating}
            onChange={handleChange("phone")}
          />
        </Box>

        <Box mt={3}>
          <TextField
            label="Website"
            value={formData.website}
            disabled={!isEditing || updating}
            onChange={handleChange("website")}
          />
        </Box>

        <button
          data-save-button
          onClick={handleSave}
          style={{ display: 'none' }}
        />
      </Box>
    </Box>
  );
};

export default ModalProfile; 