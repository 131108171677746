import { PopupButton } from "@typeform/embed-react";
import { FunctionComponent } from "react";

interface TypeFormProps {
  id: string;
  hidden: object;
  label: string;
  onClose: () => void;
  autoClose: number;
}

export const TypeForm: FunctionComponent<TypeFormProps> = ({
  id,
  hidden,
  onClose,
  label,
  autoClose,
}) => {
  return (
    <PopupButton
      id={id}
      hidden={hidden}
      autoClose={autoClose}
      style={{
        fontSize: 16,
      }}
      onClose={onClose}
      className="tw-w-full tw-py-4 ttc-button ttc-buton--large ttc-btn-secondary"
    >
      {label}
    </PopupButton>
  );
};
